

    async function initializeCard(payments) {
   const card = await payments.card();
   await card.attach('#card-container'); 
   return card; 
 }
 async function tokenize(paymentMethod) {
    let tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }
  function displayPaymentResults(status) {
    const statusContainer = document.getElementById(
      'payment-status-container'
    );
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }

 async function loadthis(_object,app_id,loc_id) {
    const appId = app_id;
    const locationId = loc_id;
  if (!window.Square) {
    throw new Error('Square.js failed to load properly');
  }
  const payments = window.Square.payments(appId, locationId);
  let card;
  try {
    card = await initializeCard(payments);
    _object.stoploader();
  } catch (e) {
    console.error('Initializing Card failed', e);
    _object.stoploader();
    return;
  }
  // Checkpoint 2.
  async function handlePaymentMethodSubmission(event, paymentMethod) {
    event.preventDefault();
    try {
      cardButton.disabled = true;
      const token = await tokenize(paymentMethod);
        displayPaymentResults('SUCCESS');
        _object.back(token.token);
        return;
    } catch (e) {
      cardButton.disabled = false;
      displayPaymentResults('FAILURE');
      _object.back(null);
      return;
    }
  }
  const cardButton = document.getElementById(
    'card-button'
  );
  cardButton.addEventListener('click', async function (event) {
    _object.startloader();
    await handlePaymentMethodSubmission(event, card);
  });
}
